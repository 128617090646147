export default {
  get _layoutHelpers () {
    return window.layoutHelpers
  },

  _exec (fn) {
    return this._layoutHelpers && fn()
  },

  getLayoutSidenav () {
    return this._exec(() => this._layoutHelpers.getLayoutSidenav()) || null
  },

  getSidenav () {
    return this._exec(() => this._layoutHelpers.getSidenav()) || null
  },

  getLayoutNavbar () {
    return this._exec(() => this._layoutHelpers.getLayoutNavbar()) || null
  },

  getLayoutFooter () {
    return this._exec(() => this._layoutHelpers.getLayoutFooter()) || null
  },

  getLayoutContainer () {
    return this._exec(() => this._layoutHelpers.getLayoutContainer()) || null
  },

  isSmallScreen () {
    return this._exec(() => this._layoutHelpers.isSmallScreen())
  },

  isLayout1 () {
    return this._exec(() => this._layoutHelpers.isLayout1())
  },

  isCollapsed () {
    return this._exec(() => this._layoutHelpers.isCollapsed())
  },

  isFixed () {
    return this._exec(() => this._layoutHelpers.isFixed())
  },

  isOffcanvas () {
    return this._exec(() => this._layoutHelpers.isOffcanvas())
  },

  isNavbarFixed () {
    return this._exec(() => this._layoutHelpers.isNavbarFixed())
  },

  isFooterFixed () {
    return this._exec(() => this._layoutHelpers.isFooterFixed())
  },

  isReversed () {
    return this._exec(() => this._layoutHelpers.isReversed())
  },

  setCollapsed (collapsed, animate = true) {
    this._exec(() => this._layoutHelpers.setCollapsed(collapsed, animate))
  },

  toggleCollapsed (animate = true) {
    this._exec(() => this._layoutHelpers.toggleCollapsed(animate))
  },

  setPosition (fixed, offcanvas) {
    this._exec(() => this._layoutHelpers.setPosition(fixed, offcanvas))
  },

  setNavbarFixed (fixed) {
    this._exec(() => this._layoutHelpers.setNavbarFixed(fixed))
  },

  setFooterFixed (fixed) {
    this._exec(() => this._layoutHelpers.setFooterFixed(fixed))
  },

  setReversed (reversed) {
    this._exec(() => this._layoutHelpers.setReversed(reversed))
  },

  update () {
    this._exec(() => this._layoutHelpers.update())
  },

  setAutoUpdate (enable) {
    this._exec(() => this._layoutHelpers.setAutoUpdate(enable))
  },

  on (event, callback) {
    this._exec(() => this._layoutHelpers.on(event, callback))
  },

  off (event) {
    this._exec(() => this._layoutHelpers.off(event))
  },

  init () {
    this._exec(() => this._layoutHelpers.init())
  },

  destroy () {
    this._exec(() => this._layoutHelpers.destroy())
  },

  hasRole(roles) {
    if (!roles) return true;
    let userRoles = JSON.parse(localStorage.getItem('mentechEmployeeDetails')).roles;

    if (!Array.isArray(roles)) return userRoles.indexOf(roles) !== -1;

    for (let i=0; i<roles.length; i++) {
      if (userRoles.indexOf(roles[i]) !== -1) {
        return true
      }
    }

    return false;
  },

  getUser() {
    let user = JSON.parse(localStorage.getItem('mentechEmployeeDetails'));

    return user;
  },

  setUser(user) {
    localStorage.setItem('mentechEmployeeDetails', JSON.stringify(user));

    return user;
  },

  getFirstName() {
    let user = JSON.parse(localStorage.getItem('mentechEmployeeDetails')).fullName;

    return user.split(" ")[0];
  },

  getMonthName(month) {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][month];
  },

  getCurrency() {
    return JSON.parse(localStorage.getItem('mentechEmployeeDetails')).currency
  },

  canEditDevice(device) {
    if (this.getUser().id === device.userId) {
      return true;
    }

    if (this.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER')) {
      return true;
    }

    return false;
  },

  getAPI(country) {
    let host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
    let list = JSON.parse(process.env.REACT_APP_API_LIST)[0][host];
    let connectTo =  (country ? country : this.getUser().connectTo);
    for(var i = 0; i < list.length; i++) {
      if(list[i].code === connectTo) {
        return list[i].api;
      }
    }
    return process.env.REACT_APP_API_URL_DA[0][host];
  },

  getLogo() {
    let host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
    return process.env.PUBLIC_URL + '/img/'+JSON.parse(process.env.REACT_APP_LOGO)[host];
  },

  getSystem() {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    let host = window.location.hostname.includes("refurbee") ? "Refurbee" : "Mentech";
    link.href = process.env.PUBLIC_URL + (host == "Refurbee" ? '/favicon-refurbee.png' : "/favicon.png");

    return host;
  },

  isValidCountry(country) {
    let list = JSON.parse(process.env.REACT_APP_API_LIST);
    for(var i = 0; i < list.length; i++) {
      if(list[i].code === country) {
        return true
      }
    }
    return false;
  },

  // Internal
  //

  _removeClass (cls) {
    this._exec(() => this._layoutHelpers._removeClass(cls))
  },

  parseError(error)
  {
    if (!error.response.data || !error.response.data.includes("Swal.fire")) {
      return "";
    }

    return error.response.data.split(',')[1].replace("'", "").replace("'", "");
  }

}
