import React from 'react'
import AuthService from "../../services/auth-service";
import helpers from "../layouts/helpers";


function InternalError() {
  return (
      <div className="container d-flex align-items-center ui-mh-100vh text-white bg-primary">
          <div className="row align-content-center align-items-start align-items-md-center w-100 py-5">

              <div className="col-md-6 order-2 order-md-1 text-md-left text-center">
                  <h1 className="display-2 font-weight-bolder mb-4">Uh... Error!</h1>
                  <div className="text-xlarge font-weight-light mb-5">Looks like something went wrong.<br/>We're working on it.</div>
                  <div className="text-big">If the problem is persisting, please try clearing cache or <a href={"mailto:support@mentech."+ helpers.getUser().connectTo.toLowerCase()} className="text-white"><u>contact us</u></a></div>
                  <a className={"btn btn-dark mt-4"} onClick={() => AuthService.logout()}>Clear cache</a>
                  <a className={"btn btn-dark mt-4 ml-2"} href={"/"}>Go back</a>
              </div>

              <div className="col-md-6 order-1 order-md-2 text-center mb-5 mb-md-0">
                  <div className="ui-device macbook w-100 rounded" style={{maxWidth: '500px'}}>
                      <img className="device-img" src={process.env.PUBLIC_URL + '/img/macbook-gold.png'}/>
                      <div className="device-content">
                          <div className={"error-device-content bg-noise"}>
                              <div className={"text-white font-weight-bolder error-device-code"}>500</div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>

  )
}

export default InternalError
