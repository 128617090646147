import React, { Component } from 'react'
import InternalError from './InternalError/InternalError'
import axios from "axios";
import helpers from "./layouts/helpers";

class ErrorBoundary extends Component {
  state = {
    error: '',
  };

  componentDidCatch(error, info) {
    const stackArr = info.componentStack.split('\n');
    const fileInfo = stackArr[1].split('/').pop();
    const [file, line, column] = fileInfo.split(':');
    //axios.post(helpers.getAPI() + '/partner/api/log/error', JSON.stringify({error: (file + " " + line + " " + column)}));
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return <InternalError/>;
    }

    return this.props.children;
  }

}

export default ErrorBoundary;

